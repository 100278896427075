import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import store from './services/store';
import { Provider } from 'react-redux';
import UploadService from './services/UploadService.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <UploadService />
            <App />
        </Provider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
