import './app.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from './pages/Login.js';
import NewEntryPage from './pages/NewEntry.js';
import PhotoNewPage from './pages/PhotoNew.js';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/new" element={<NewEntryPage />} />
                <Route path="/photo-new" element={<PhotoNewPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
