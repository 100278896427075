import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../services/Database.js";
import { useSelector } from 'react-redux';

/**
 * Dialog containing all pending photo uploads (and its records)
 */
function PendingUploadsDialog({ isOpen, onClose }) {
    
    // maximum of this much results are visible
    const maxVisibleResults = 3;
    
    // query the database for pending uploads
    const pendingUploads = useLiveQuery(
        () => db.uploads.limit(maxVisibleResults).toArray()
    );
    // query state wrapper
    const uploadQueueState = useSelector(state => state.uploadQueue);
    
    // size of the queue
    const queueSize = (typeof uploadQueueState !== 'undefined') ? uploadQueueState.queue.length : 0;
    // is there any uploading-pending record?
    const isUploading = (typeof uploadQueueState !== 'undefined') ? (uploadQueueState.currentState === 'UPLOADING') : false;
    
    // is there anything at all?
    const isAny = (typeof pendingUploads !== 'undefined' && pendingUploads.length > 0);
    // are there more than maximum visible items?
    const isAboveMax = (isAny && queueSize > maxVisibleResults);
    // total pending items count
    const totalPending = (isAny) ? queueSize : 0;
    
    // state text depending on the probed state
    const stateText = isUploading ? (<span className="centered">Probíhá nahrávání...</span>) : (<span className="centered">Čekání na stabilní spojení...</span>);
    
    // omission element
    const omit = (isAboveMax) ? <span className="centered">...<br/></span> : <span></span>
    
    // complete dialog content
    const comp = isAny ?
        <span>
            {stateText}
            {pendingUploads?.map(upl => <span className="smallText" key="{upl.id}">
                {upl.dateTimeStart}: {upl.deviceId}, <strong>{upl.photoCount}</strong> fotografií<br />
            </span>)}
            {omit}
            <span className="smallText centered">Celkem <strong>{totalPending}</strong> záznamů</span>
        </span>
        :
        <span>Žádné fotografie nečekají ve frontě.</span>;
    
    return (
        <Dialog open={isOpen}>
            <DialogTitle id="alert-dialog-title">
                {"Fronta"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {comp}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Zavřít</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PendingUploadsDialog;


