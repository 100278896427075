import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * Confirm dialog after the upload has been initiated
 */
function UploadingDialog({ onRepeat, onBack }) {
    return (
        <Dialog open={true}>
            <DialogTitle id="alert-dialog-title">
                {"Probíhá nahrávání"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Obrázky jsou nahrávány. Chcete pokračovat v dokumentaci tohoto zařízení, nebo si jej přejete dokončit?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack}>Dokončit</Button>
                <Button onClick={onRepeat}>Pokračovat</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadingDialog;