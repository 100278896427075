import React, { Component } from 'react'
import { withRouter } from '../extensions/withRouter.js';
import InfoBox from '../components/InfoBox.js';
import TitleBar from '../components/TitleBar.js';
import PageBody from '../components/PageBody.js';
import IconButton from '@mui/material/IconButton';
import BarcodeScanner from '../components/BarcodeScanner.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faCircleUser, faMicrochip, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import PageFooter from '../components/PageFooter.js';

/**
 * Scanned code component - displays either the scanned code, or dummy text
 */
function ScannedCode({ code }) {
    if (code !== null && code.length > 0) {
        return (
            <div className="full-width">
                <div className="login-credentials">
                    <div className="cred-container">
                        <div className="cred-left">
                            <FontAwesomeIcon size="1x" icon={faMicrochip} />
                        </div>
                        <div className="">
                            Kód: <strong>{code}</strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="full-width">
                <div className="login-credentials">
                    Žádné údaje zatím nebyly načteny<br />
                </div>
            </div>
        );
    }
}

/**
 * New page component
 */
class NewEntryPage extends Component {

    constructor(props) {
        super(props);

        // component state init
        this.state = {
            scannedCode: null,
            confirmDisabled: true
        };

        this.handleScan = this.handleScan.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleAcceptDeviceIdClick = this.handleAcceptDeviceIdClick.bind(this);
        this.scannerRef = React.createRef();
    }

    // "logout" callback - clears the identity and moves back to previous page
    handleLogout() {
        this.scannerRef.current.handleTerminate();
        sessionStorage.setItem('user-identity', null);
        this.props.navigate('/', { replace: true });
    }

    // barcode scan callback
    handleScan(result, format) {
        
        if (format !== "barcode") {
            return;
        }
        
        this.setState({
            scannedCode: result,
            confirmDisabled: false
        });
    }
    
    // when the device code is accepted, move to photodocumentation state
    handleAcceptDeviceIdClick() {
        this.scannerRef.current.handleTerminate();
        sessionStorage.setItem('device-id', this.state.scannedCode);
        this.props.navigate('/photo-new');
    }

    render() {

        // user identity is stored in session
        const storedData = sessionStorage.getItem('user-identity');
        const userData = JSON.parse(storedData);

        return (
            <div className="app">
                <TitleBar>Identifikace zařízení</TitleBar>
                <InfoBox>
                    <div className="cred-container cred-cont-bottommargin">
                        <div className="cred-left">
                            <FontAwesomeIcon size="lg" icon={faCircleUser} />
                        </div>
                        <div className="cred-right">
                            {userData.name} {userData.surname}<br />{userData.id}
                        </div>
                        <div className="cred-right cred-right-end">
                            <IconButton size="small" className="white" onClick={this.handleLogout}>
                                <FontAwesomeIcon size="lg" icon={faRightFromBracket} />
                            </IconButton>
                        </div>
                    </div>
                    <hr />
                    Načtěte čárový kód zařízení.<br />
                    Níže pak potvrďte správnost informací.
                </InfoBox>
                <PageBody>
                    <div className="full-width">
                        <BarcodeScanner ref={this.scannerRef} onDecode={this.handleScan}></BarcodeScanner>
                    </div>
                    <ScannedCode code={this.state.scannedCode} />
                </PageBody>
                <PageFooter>
                    <IconButton disabled={this.state.confirmDisabled} onClick={this.handleAcceptDeviceIdClick}>
                        <FontAwesomeIcon size="1x" icon={faRightToBracket} />
                    </IconButton>
                </PageFooter>
            </div>
        );
    }
}

export default withRouter(NewEntryPage);
