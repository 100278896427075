import React, { Component } from 'react';
import { withRouter } from '../extensions/withRouter.js';
import IconButton from '@mui/material/IconButton';
import InfoBox from '../components/InfoBox.js';
import TitleBar from '../components/TitleBar.js';
import PageBody from '../components/PageBody.js';
import BarcodeScanner from '../components/BarcodeScanner.js';
import PageFooter from '../components/PageFooter.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { VERSION } from '../version.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Credentials component - displays either a credential set or dummy text
 */
function Credentials({ credentials }) {
    if (credentials !== null && credentials.name !== null && credentials.surname !== null && credentials.id !== null) {
        return (
            <div className="full-width">
                <div className="login-credentials">
                    <div className="cred-container">
                        <div className="cred-left">
                            <FontAwesomeIcon size="lg" icon={faCircleUser} />
                        </div>
                        <div className="cred-right">
                            <strong>{credentials.name} {credentials.surname}</strong><br />
                            {credentials.id}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="full-width">
                <div className="login-credentials">
                    Žádné údaje zatím nebyly načteny<br />
                </div>
           </div>
        );
    }
}

/**
 * Login page component
 */
class LoginPage extends Component {

    constructor(props) {
        super(props);

        // component state init
        this.state = {
            delay: 100,
            result: '',
            scannedData: {
                name: null,
                surname: null,
                id: null
            },
            debugVal: "",
            confirmDisabled: true
        };

        this.handleScan = this.handleScan.bind(this);
        this.handleAcceptIdentityClick = this.handleAcceptIdentityClick.bind(this);

        this.scannerRef = React.createRef();

        if (typeof window['isUpdateAvailable'] !== 'undefined') {
            window['isUpdateAvailable'].then(isAvailable => {
                console.log("isUpdateAvailable resolved!");
                if (isAvailable) {
                    toast.info('Nová verze je k dispozici! Prosím, restartujte aplikaci, aby se změny projevily!', {
                        position: "bottom-center",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
        }
    }

    // QR code scan callback
    handleScan(data, type) {

        if (typeof(data) !== 'undefined' && data && type === 'qr') {

            // parse text
            var text = data;
            var parts = text.split('&');

            var name = null;
            var surname = null;
            var id = null;
            // parse out all the required parts (name, surname, ID)
            for (var i in parts) {
                var kval = parts[i].split('=');
                if (!kval || kval.length !== 2)
                    continue;

                if (kval[0] === "name") {
                    name = kval[1];
                }
                else if (kval[0] === "surname") {
                    surname = kval[1];
                }
                else if (kval[0] === "id") {
                    id = kval[1];
                }
            }

            var scanned = {
                name: null,
                surname: null,
                id: null
            };
            var confirmDisabledVal = true;

            if (name !== null && surname !== null && id !== null) {
                confirmDisabledVal = false;
                scanned.name = name;
                scanned.surname = surname;
                scanned.id = id;
            }

            // update the component state
            this.setState({
                result: data.text,
                scannedData: scanned,
                confirmDisabled: confirmDisabledVal
            });
        }
        else {
            this.setState({
                result: null
            });
        }
    }

    handleError(err){
        console.error(err);
    }

    // when the user accepts the scanned identity, move to new entry page
    handleAcceptIdentityClick() {
        this.scannerRef.current.handleTerminate();
        sessionStorage.setItem('user-identity', JSON.stringify(this.state.scannedData));
        this.props.navigate('/new')
    }

    render() {
        var appLocationInfo = (<>Development</>);
        if (window.location.hostname.includes("app.carrier-qa.internal")) {
            appLocationInfo = (<>Production</>);
        }
        
        return (
            <div className="app">
                <TitleBar>Přihlášení</TitleBar>
                <InfoBox>
                    Načtete Váš přístupový QR kód.<br/>
                    Tlačítkem níže potvrďte správnost údajů.
                </InfoBox>
                <PageBody>
                    <div className="full-width">
                        <BarcodeScanner ref={this.scannerRef} onDecode={this.handleScan}></BarcodeScanner>
                    </div>
                    <Credentials className="full-width" credentials={this.state.scannedData} />
                    <div className="full-width">
                        <div className="build-info">
                            Build {VERSION}<br />
                            {appLocationInfo}
                        </div>
                    </div>
                </PageBody>
                <PageFooter>
                    <IconButton disabled={this.state.confirmDisabled} onClick={this.handleAcceptIdentityClick}>
                        <FontAwesomeIcon size="1x" icon={faRightToBracket} />
                    </IconButton>
                </PageFooter>
                <ToastContainer />
            </div>
        );
    }
}

export default withRouter(LoginPage);
