/**
 * Wrapper for page body
 */
function PageBody({ children }) {
    return (
        <div className="app-body">
            {children}
        </div>
    );
}

export default PageBody;

