import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../extensions/withRouter.js';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InfoBox from '../components/InfoBox.js';
import TitleBar from '../components/TitleBar.js';
import PageBody from '../components/PageBody.js';
import PhotoPrompter from '../components/PhotoPrompter.js';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import AppConfig from '../config.json';
import ConfirmDialog from '../components/ConfirmDialog.js';
import UploadingDialog from '../components/UploadingDialog.js';
import PageFooter from '../components/PageFooter.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane,faCircleUser, faMicrochip, faTrashCan, faDownLong } from '@fortawesome/free-solid-svg-icons';
import { setCurrentState, addToQueue, removeFromQueue } from '../services/UploadQueue.js';
import { db } from '../services/Database.js';

/**
 * Component to display progress bar
 */
const BorderLinearProgress = styled(LinearProgress) (({ theme, value }) => ({
    height: 30,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        backgroundColor: value < 99 ? (theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark) : theme.palette.warning.light
    }
}));

/**
 * New photodocumentation entry page component
 */
class PhotoNewPage extends Component {

    constructor(props) {
        super(props);

        // component state init
        this.state = {
            photoArray: [],
            progress: 0,
            positionCounter: 0,
            dialogState: 'none'
        };

        this.handleBack = this.handleBack.bind(this);
        this.handleSendClick = this.handleSendClick.bind(this);
        this.handleImageCallback = this.handleImageCallback.bind(this);
        this.onConfirmSuccess = this.onConfirmSuccess.bind(this);
        this.onCancelQuery = this.onCancelQuery.bind(this);
        this.onConfirmUploading = this.onConfirmUploading.bind(this);

        this.workerInstance = new Worker(new URL('../workers/PrepareUpload.js', import.meta.url));
    }

    // handle back button press - clear the device ID and move back
    handleBack() {
        sessionStorage.setItem('device-id', null);
        this.props.navigate('/new', { replace: true });
    }
    
    // image snapshot complete callback
    handleImageCallback(imageFileName) {
        
        var assignedPosition = this.state.positionCounter + 1;
        var arr = this.state.photoArray;
        arr.unshift({ position: assignedPosition, num: arr.length + 1, fileName: imageFileName });
        
        var progress = Math.round(100.0 * arr.length / AppConfig.maximumPhotosPerBatch);

        this.setState({
            photoArray: arr,
            progress: progress,
            positionCounter: assignedPosition
        });
    }
    
    // "send" button callback
    handleSendClick() {
        this.setState({
            dialogState: 'confirm'
        });
    }
    
    // clicked on delete button in the image corner (delete specific photo)
    handleDeleteClick(position) {
        var arr = this.state.photoArray;
        
        var foundIndex = -1;
        for (var i in arr) {
            if (arr[i].position === position) {
                foundIndex = i;
            }
        }
        
        if (foundIndex < 0) {
            return;
        }
        
        arr.splice(foundIndex, 1);
        
        for (var idx in arr) {
            arr[idx].num = arr.length - idx;
        }
        
        var progress = Math.round(100.0 * arr.length / AppConfig.maximumPhotosPerBatch);

        this.setState({
            photoArray: arr,
            progress: progress
        });
    }
    
    // when the user confirms that he/she wants to upload the photo array
    async onConfirmSuccess() {
       
        const storedData = sessionStorage.getItem('user-identity');
        const userData = JSON.parse(storedData);
        const storedDeviceId = sessionStorage.getItem('device-id');

        // format date for upload
        const formatDateTime = (dateTime) => {
            const m = (dateTime.getMonth() + 1);
            const d = dateTime.getDate();
            const h = dateTime.getHours();
            const mn = (dateTime.getMinutes() < 10) ? "0"+dateTime.getMinutes() : dateTime.getMinutes();
        
            return d+"."+m+". "+h+":"+mn;
        };
        
        // add upload record to database, obtain the ID
        const curUploadId = await db.uploads.add({
            userId: userData.name + " " + userData.surname + ", " + userData.id,
            deviceId: storedDeviceId,
            captureInfo: window.navigator.userAgent,
            photoCount: this.state.photoArray.length,
            dateTimeStart: formatDateTime(new Date())
        });

        var workerData = {
            userData: userData,
            deviceId: storedDeviceId,
            captureInfo: window.navigator.userAgent,
            photos: this.state.photoArray,
            dateTimeStart: new Date(),
            uploadId: curUploadId
        };

        this.workerInstance.postMessage(workerData);

        this.workerInstance.onmessage = (res) => {
            if (typeof res.data !== 'undefined' && res.data > 0) {
                this.props.addToQueue({ id: res.data });
            }
        };
        
        // a function to transform the file to a binary dump (read the contents as binary)
        /*const loadFileAsBinary = (filePath) => {
            return new Promise((resolve) => {
                var fr = new FileReader();
                fr.onload = function() {
                    resolve(fr.result);
                };
                fr.readAsArrayBuffer(filePath);
            });
        };
        
        // transform all taken photos to an array of binary objects and store them to database
        for (var i in this.state.photoArray) {
            const fileEntry = this.state.photoArray[i];
            const fileName = fileEntry.fileName;
           
            const contents = await loadFileAsBinary(fileName);
            
            await db.uploadFiles.add({
                uploadId: curUploadId,
                fileDump: contents
            });
        }*/
    
        // add the ID to queue so we can display it in progress state
        //this.props.addToQueue({ id: curUploadId });
            
        this.setState({
            dialogState: 'uploading'
        });
    }

    // cancel any query - return back to initial dialog state
    onCancelQuery() {
        this.setState({
            dialogState: 'none'
        });
    }
    
    // when uploading is confirmed
    onConfirmUploading() {
        this.setState({
            dialogState: 'none',
            photoArray: [],
            progress: 0
        });
    }

    render() {

        const storedData = sessionStorage.getItem('user-identity');
        const userData = JSON.parse(storedData);
        const storedDeviceId = sessionStorage.getItem('device-id');
        
        // disambiguate between dialog states
        var dialogElement = null;
        // confirm dialog - "really want to upload?"
        if (this.state.dialogState === 'confirm') {
            dialogElement = <ConfirmDialog onSuccess={this.onConfirmSuccess} onCancel={this.onCancelQuery} />;
        }
        // uploading dialog - "uploading... continue?"
        else if (this.state.dialogState === 'uploading') {
            dialogElement = <UploadingDialog onRepeat={this.onConfirmUploading} onBack={this.handleBack} />;
        }
        
        // dummy element - we use this when no photos has been taken yet
        var dummyElement = null;
        if (this.state.photoArray.length === 0) {
            dummyElement = (<div className="dummy-placeholder">
                    Zatím jste nepořídili žádnou fotografii. Tlačítkem níže spusťte fotoaparát.<br/><br/><br />
                    <FontAwesomeIcon icon={faDownLong} size="2x" className="pulsatingDown" />
                </div>);
        }

        return (
            <div className="app-nocenter">
                <TitleBar>Fotodokumentace</TitleBar>
                <InfoBox>
                    <table>
                        <tbody>
                            <tr>
                                <th><FontAwesomeIcon icon={faCircleUser} /></th>
                                <td>{userData.name} {userData.surname}</td>
                            </tr>
                            <tr>
                                <th><FontAwesomeIcon icon={faMicrochip} /></th>
                                <td>{storedDeviceId}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <div className="full-width">
                        <Box sx={{ width: '100%' }}>
                            <BorderLinearProgress variant="determinate" value={this.state.progress} />
                        </Box>
                        <div className="left-text">
                            {this.state.photoArray.length} pořízeno
                            <span className="right-text">
                                nejvýše {AppConfig.maximumPhotosPerBatch}
                            </span>
                        </div>
                    </div>
                </InfoBox>
                <PageBody>
                    <div className="img-container">
                        {this.state.photoArray.map((imageFile) => (
                            <div key={imageFile.position} className="img-preview-parent">
                                <Button className="img-delete" onClick={() => this.handleDeleteClick(imageFile.position)}>
                                    <FontAwesomeIcon icon={faTrashCan} size="2x" />
                                </Button>
                                <img src={URL.createObjectURL(imageFile.fileName)} alt="" className="img-preview" />
                            </div>
                        ) )}
                    </div>
                    {dummyElement}
                    {dialogElement}
                </PageBody>
                <PageFooter>
                    <IconButton onClick={this.handleBack}>
                        <FontAwesomeIcon size="1x" icon={faTrashCan} />
                    </IconButton>
                    <PhotoPrompter imageCallback={this.handleImageCallback} disabled={this.state.photoArray.length >= AppConfig.maximumPhotosPerBatch} />
                    <IconButton disabled={this.state.photoArray.length === 0} onClick={this.handleSendClick}>
                        <FontAwesomeIcon size="1x" icon={faPaperPlane} />
                    </IconButton>
                </PageFooter>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentState: (payload) => dispatch(setCurrentState(payload)),
        addToQueue: (payload) => dispatch(addToQueue(payload)),
        removeFromQueue: (payload) => dispatch(removeFromQueue(payload))
    };
};

export default connect(null, mapDispatchToProps)(withRouter(PhotoNewPage));
