import React, {useState} from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import PendingUploadsDialog from './PendingUploadsDialog.js';

/**
 * Title bar of all pages
 */
function TitleBar({ children }) {
    
    const [modalUploadIsOpen, setModalUploadIsOpen] = useState(false);

    const openUploadModal =()=>{
        setModalUploadIsOpen(true);
    };
    const closeUploadModal =()=>{
        setModalUploadIsOpen(false);
    };
    
    // state of upload queue
    const uploadQueueState = useSelector(state => state.uploadQueue);
    
    // size of upload queue
    const queueSize = (typeof uploadQueueState !== 'undefined') ? uploadQueueState.queue.length : 0;
    // is there something currently being uploaded?
    const isUploading = (typeof uploadQueueState !== 'undefined') ? (uploadQueueState.currentState === 'UPLOADING') : false;
    
    // pending class type - depending on upload state, we choose the class
    const classNamePending = isUploading ? "pending white" : "white";

    return (
        <div className="app-header">
            <div className="app-header-item">
                <img src="img/icons/icon-192x192.png" alt="Carrier Logo" />
            </div>
            <div className="app-header-item">
                {children}
            </div>
            <div className="app-header-props">
                <div className="prop-box">
                    <IconButton onClick={openUploadModal}>
                        <Badge badgeContent={queueSize} color="primary">
                            <FontAwesomeIcon icon={faCloudArrowUp} className={classNamePending} />
                        </Badge>
                    </IconButton>
                </div>
            </div>
            <PendingUploadsDialog isOpen={modalUploadIsOpen} onClose={closeUploadModal} />
        </div>
    );
}

export default TitleBar;

