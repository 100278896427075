/**
 * A small helper component to display infobox structure
 */
function InfoBox({ children }) {
    return (
        <div className="info-box">{children}</div>
    );
}

export default InfoBox;
