import { createSlice } from '@reduxjs/toolkit'

/**
 * Upload queue service
 */
export const UploadQueueSlice = createSlice({
    name: 'UploadQueue',
    initialState: {
        currentState: 'NONE',
        queue: []
    },
    reducers: {
        setCurrentState: (state, action) => {
            state.currentState = action.payload;
        },
        addToQueue: (state, action) => {
            state.queue.push(action.payload);
        },
        removeFromQueue: (state, action) => {
            state.queue = state.queue.filter((item) => {
                return (item.id !== action.payload.id);
            });
        },
        forceQueue: (state, action) => {
            state.queue = action.payload;
        }
    }
});

export const { setCurrentState, addToQueue, removeFromQueue, forceQueue } = UploadQueueSlice.actions;

export default UploadQueueSlice.reducer
