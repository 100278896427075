import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/**
 * Confirm dialog used for confirming the upload of outgoing photos
 */
function ConfirmDialog({ onSuccess, onCancel }) {
    return (
        <Dialog open={true}>
            <DialogTitle id="alert-dialog-title">
                {"Potvrdit odeslání"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Doopravdy chcete odeslat pořízenou fotodokumentaci na server?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={"error"} onClick={onCancel}>Zrušit</Button>
                <Button onClick={onSuccess} autoFocus>
                    Odeslat
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
