import React, { useRef } from "react";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';

/**
 * Prompter for photo capture; this opens a dialog for photo capture and then invokes the callback upon completion
 */
function PhotoPrompter({ imageCallback, disabled }) {
    const handleFileInput = useRef(null);

    const handleClick = () => {
        handleFileInput.current.click();
    };

    const handleImageChange = (event) => {
        imageCallback(event.target.files[0]);
    };

    return (
        <>
            <IconButton disabled={disabled} onClick={handleClick}>
                <FontAwesomeIcon size="1x" icon={faCamera} />
            </IconButton>
            <label className="no-display">
                <input className="no-display" type="file" accept="image/*" capture="environment" ref={handleFileInput} onChange={handleImageChange} />
            </label>
        </>
    );
};

export default PhotoPrompter;