import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
} from "react-router-dom";

/**
 * Back listener to prevent unwanted back navigation
 */
export const useBackListener = (callback) => {
    const location = useLocation();
    const navType = useNavigationType();

    useEffect(() => {
        if (navType === "POP" && location.key !== "default") {
            callback();
        }
    }, [location, navType, callback]);
};
