import { PureComponent } from "react";
import { BarcodeFormat, BrowserMultiFormatReader } from "@zxing/library";
import { faCameraRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Barcode scanner component using zxing library
 * The output is displayed as a stream from the environment camera into a <video> element
 */
class BarcodeScanner extends PureComponent {

    constructor(props) {
        super(props);
        this.onDecode = props.onDecode;
        this.cameraPreference = localStorage.getItem("cameraPreference");
        if (!this.cameraPreference)
            this.cameraPreference = 0;
        else {
            this.cameraPreference = parseInt(this.cameraPreference);
        }
        this.codeReader = new BrowserMultiFormatReader(undefined, 500);
        this.rendering = false;
        this.cameraList = [];
        this.handleChangeCamera = this.handleChangeCamera.bind(this);
        this.handleTerminate = this.handleTerminate.bind(this);
        this.state = { camChangeEnabled: true };
    }

    handleTerminate() {
        this.codeReader.stopAsyncDecode();
        this.codeReader.stopContinuousDecode();
    }

    handleChangeCamera() {

        // disable camera change for a few moments
        this.setState({
            camChangeEnabled : false
        });

        // after some time, enable the change again
        var oldThis = this;
        setTimeout(function() {
            oldThis.setState({
                camChangeEnabled : true
            });
        }, 1500);

        // disable currently running decoding stream
        this.codeReader.stopAsyncDecode();
        this.codeReader.stopContinuousDecode();

        // move camera preference
        this.cameraPreference++;
        if (this.cameraList.length > 0) {
            this.cameraPreference = this.cameraPreference % this.cameraList.length;
        }
        // allow for re-render and re-starting of decoding
        this.rendering = false;
        localStorage.setItem("cameraPreference", this.cameraPreference);

        // force the component to update
        this.forceUpdate();
    }

    render() {

        if (!this.rendering) {
            this.rendering = true;

            // list all available devices
            this.codeReader.listVideoInputDevices()
                .then((videoInputDevices) => {

                    // filter those, who has "back" or "environment" in its name
                    this.cameraList = [];
                    for (var i in videoInputDevices) {
                        if (videoInputDevices[i].label.includes("back") || videoInputDevices[i].label.includes("environment") || videoInputDevices[i].label.includes("rear")) {
                            this.cameraList.push(videoInputDevices[i]);
                        }
                    }
                    // fallback if no camera has the given name
                    if (this.cameraList.length === 0) {
                        this.cameraList = videoInputDevices;
                    }

                    // start decoding on given element
                    this.codeReader.decodeFromVideoDevice(this.cameraList[this.cameraPreference % this.cameraList.length].deviceId, 'video', (result, err) => {
                        if (result) {
                            var type = "barcode";
                            if (result.format === BarcodeFormat.QR_CODE) {
                                type = "qr";
                            }
                            
                            this.onDecode(result.getText(), type);
                        }
                    });
                });
        }

        return (
            <div className="code-scanner rel">
                <div className="code-scanner-inner">
                    <video id="video" />
                    <button className="camera-selecter" disabled={!this.state.camChangeEnabled} onClick={this.handleChangeCamera}><FontAwesomeIcon size="1x" icon={faCameraRotate} /></button>
                    <div className="camera-info">{this.cameraPreference+1}</div>
                </div>
            </div>
        );
    }
}

export default BarcodeScanner;
