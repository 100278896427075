import { configureStore } from '@reduxjs/toolkit';
import UploadQueueReducer from './UploadQueue.js';

/**
 * Store dummy reducer
 */
export default configureStore({
    reducer: {
        uploadQueue: UploadQueueReducer
    }
})
