import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentState, removeFromQueue, forceQueue } from './UploadQueue.js';
import { db } from './Database.js';
import Uploader from '../modules/Uploader.js';

/**
 * Timer for repeating the query upon failure
 */
const repeatTimeout = async () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, 20000);
    });
};

/**
 * Upload service running "in the background"
 */
const UploadService = () => {
    const dispatch = useDispatch();
    const uploadQueue = useSelector((state) => state.uploadQueue.queue);
    
    // select all uploads
    db.uploads.toArray().then((recs) => {
        if (uploadQueue.length > 0) {
            return;
        }
        if (recs.length > 0) {
            dispatch(forceQueue(recs));
        }
    }, [dispatch, uploadQueue]);

    React.useEffect(() => {
        if (uploadQueue.length > 0) {
            const processQueue = async () => {
                
                var needRepeat = false;
                
                const currentItem = uploadQueue[0];
                try {
                    dispatch(setCurrentState('UPLOADING'));
                    
                    var allowRemove = false;
                    
                    const uploadRecord = await db.uploads.get(currentItem.id);
                    if (typeof(uploadRecord) === 'undefined') {
                        allowRemove = true;
                    }
                    else {
                        var formData = new FormData();
                        
                        formData.append('deviceId', uploadRecord.deviceId);
                        formData.append('userId', uploadRecord.userId);
                        formData.append('captureInfo', uploadRecord.captureInfo);
                        
                        const uploadFiles = await db.uploadFiles.where('uploadId').equals(currentItem.id);

                        await uploadFiles.each((fl, cur) => {
                            const blob = new Blob([fl.fileDump], { type: "image/jpeg" });
                            const file = new File([blob], currentItem.id + "-" + fl.id + ".jpg", { type: "image/jpeg" });
                            
                            formData.append("images[]", file);
                        });
                        
                        const uploader = new Uploader();
                        const response = await uploader.uploadBackground(formData);
                        
                        if (response.status === 200) {
                            allowRemove = true;
                        }
                        else {
                            needRepeat = true;
                        }
                    }

                    if (allowRemove) {
                        await db.uploadFiles.where('uploadId').equals(currentItem.id).delete();
                        await db.uploads.where('id').equals(currentItem.id).delete();
                        dispatch(removeFromQueue(currentItem));
                    }
                } catch (error) {
                    needRepeat = true;
                    console.error('Error uploading:', error);
                }

                if (needRepeat) {
                    dispatch(setCurrentState('NONE'));
                    await repeatTimeout();
                }

                if (uploadQueue.length > 1 || needRepeat) {
                    processQueue();
                }
                
                dispatch(setCurrentState('NONE'));
            };

            processQueue();
        }
    }, [uploadQueue, dispatch]);

    return null;
};

export default UploadService;
